import axios from 'axios';
import apiBaseUrl from './apiBaseUrl';

import { auth } from './firebase';

const axiosInstance = axios.create({ baseURL: apiBaseUrl });

axiosInstance.interceptors.request.use(
  async (axiosConfig) => {
    if (!axiosConfig.headers) axiosConfig.headers = {};
    if (process.env.NEXT_PUBLIC_APP) axiosConfig.headers['x-synergie-app'] = process.env.NEXT_PUBLIC_APP;

    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      if (token) axiosConfig.headers.Authorization = `Bearer ${token}`;
      else delete axiosConfig.headers.Authorization;
    } else delete axiosConfig.headers.Authorization;
    return axiosConfig;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;

import * as Sentry from '@sentry/nextjs';

const isProduction = process.env.NODE_ENV === 'production';

export function logSentryError(error: Error): void {
  if (isProduction) Sentry.captureException(error);
  else console.error(error);
}

export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb): void {
  if (isProduction) Sentry.addBreadcrumb(breadcrumb);
  else console.log(breadcrumb);
}

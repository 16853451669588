import getCookie from './getCookie';

declare global {
  interface Window {
    plausible?: any;
  }
}

export function plausibleEvent(eventName: string, customProps?: any) {
  const props = {
    website: process.env.NEXT_PUBLIC_APP || 'synergiejobs',
    ...customProps,
  };
  const ABcookie = getCookie('AB-test');
  if (ABcookie) props['AB-version'] = ABcookie;
  if (typeof window !== 'undefined' && window.plausible) window.plausible(eventName, { props });
}

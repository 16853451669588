export const i18n = {
  locales: ['nl', 'fr', 'en', 'pl', 'ro'],
  defaultLocale: 'nl',
} as const;

export type Locale = (typeof i18n)['locales'][number];
export type Locales = (typeof i18n)['locales'];

const synergiejobsDictionary = {
  en: () => import('./synergiejobs.be/en.json').then((module) => module.default),
  nl: () => import('./synergiejobs.be/nl.json').then((module) => module.default),
  fr: () => import('./synergiejobs.be/fr.json').then((module) => module.default),
  pl: () => import('./synergiejobs.be/pl.json').then((module) => module.default),
  ro: () => import('./synergiejobs.be/ro.json').then((module) => module.default),
};

const synergiecareersDictionary = {
  nl: () => import('./synergiecareers.be/nl.json').then((module) => module.default),
  fr: () => import('./synergiecareers.be/fr.json').then((module) => module.default),
};

const synergieconstructDictionary = {
  nl: () => import('./synergieconstruct.be/nl.json').then((module) => module.default),
  fr: () => import('./synergieconstruct.be/fr.json').then((module) => module.default),
};
const sAndyouDictionary = {
  nl: () => import('./sandyou.be/nl.json').then((module) => module.default),
  fr: () => import('./sandyou.be/fr.json').then((module) => module.default),
};
const werkenBijSynergieDictionary = {
  nl: () => import('./werkenbijsynergie.be/nl.json').then((module) => module.default),
  fr: () => import('./werkenbijsynergie.be/fr.json').then((module) => module.default),
};

export const getSynergiejobsDictionary = async (locale: Locale) =>
  synergiejobsDictionary[locale]?.() ?? synergiejobsDictionary.en();
export const getSynergiecareersDictionary = async (locale: Partial<Locale>) =>
  synergiecareersDictionary[locale]?.() ?? synergiecareersDictionary.nl();
export const getSynergieConstructDictionary = async (locale: Partial<Locale>) =>
  synergieconstructDictionary[locale]?.() ?? synergieconstructDictionary.nl();
export const getSAndyouDictionary = async (locale: Partial<Locale>) => sAndyouDictionary[locale]?.() ?? sAndyouDictionary.nl();
export const getWerkenBijSynergieDictionary = async (locale: Partial<Locale>) =>
  werkenBijSynergieDictionary[locale]?.() ?? werkenBijSynergieDictionary.nl();

export type SynergiejobsDictionary = Awaited<ReturnType<typeof getSynergiejobsDictionary>>;
export type SynergiecareersDictionary = Awaited<ReturnType<typeof getSynergiecareersDictionary>>;
export type SynergieconstructDictionary = Awaited<ReturnType<typeof getSynergieConstructDictionary>>;
export type SAndyouDictionary = Awaited<ReturnType<typeof getSAndyouDictionary>>;
export type WerkenBijSynergieDictionary = Awaited<ReturnType<typeof getWerkenBijSynergieDictionary>>;

export type Dictionaries = SynergiejobsDictionary &
  SynergiecareersDictionary &
  SynergieconstructDictionary &
  SAndyouDictionary &
  WerkenBijSynergieDictionary;

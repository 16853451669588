import { createContext, useEffect, useContext } from 'react';

import useData from '../hooks/useData';
import { useUser } from './UserContext';

interface MySynergieNotificationsContextType {
  mySynergieNotifications: MySynergieNotification[];
  refetchMySynergieNotifications: () => Promise<void>;
  isLoading: boolean;
  errorMessage: string | null;
}

const MySynergieNotificationsContext = createContext<MySynergieNotificationsContextType | undefined>(undefined);

function MySynergieNotificationsContextProvider({ children }) {
  const { candidate } = useUser();
  const {
    data: mySynergieNotifications,
    refetchData,
    isLoading,
    errorMessage,
  } = useData<MySynergieNotification[]>(candidate ? `/notifications/history` : '');

  useEffect(() => {
    if (candidate && mySynergieNotifications) refetchData();
  }, [candidate]);

  return (
    <MySynergieNotificationsContext.Provider
      value={{
        mySynergieNotifications: mySynergieNotifications || [],
        refetchMySynergieNotifications: refetchData,
        isLoading,
        errorMessage,
      }}
    >
      {children}
    </MySynergieNotificationsContext.Provider>
  );
}

function useMySynergieNotifications() {
  const context = useContext(MySynergieNotificationsContext);
  if (context === undefined)
    throw new Error('useMySynergieNotifications must be used within a MySynergieNotificationsContextProvider');

  return context;
}

export { useMySynergieNotifications };
export default MySynergieNotificationsContextProvider;

import React, { useEffect } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { plausibleEvent } from '../../utils/plausibleEvent';
import { pageview } from '../../utils/gtag';

// @INFO
// This component should always remain at the top level of the app to prevent duplicate tracking
// Both initial renders as well as page navigations trigger Plausible pageview events so it's important that components higher in the tree don't cause rerenders (f.e. in Context)

function PlausibleTracking() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const router = useRouter();

  const _push = router.push.bind(router);

  router.push = (href, options) => {
    pageview(href); // GA doesn't make much sence here, but will be removed later
    _push(href, options);
  };

  useEffect(() => {
    plausibleEvent('pageview'); // initial pageview
  }, [pathname, searchParams]);

  // Docs for adding plausible https://plausible.io/docs/custom-event-goals
  return (
    <>
      <Script
        defer
        data-domain={process.env.NEXT_PUBLIC_PLAUSIBLE_DATA_DOMAIN}
        src={process.env.NEXT_PUBLIC_PLAUSIBLE_DATA_URL}
      />
      <Script
        id="plausible-tracking"
        dangerouslySetInnerHTML={{
          __html: `
          window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
          `,
        }}
      />
    </>
  );
}

export default PlausibleTracking;

import { createElement, cloneElement, isValidElement } from 'react';
import { Dictionaries } from './i18n-config';

function parseHtmlString(htmlString, values = {}) {
  const htmlRegex = /(<[^>]+>)/g; // Regex to match HTML tags
  const placeholderRegex = /\{(\w+)\}/g; // Regex to match placeholders like {link}

  const replacedValueSegments = htmlString.split(placeholderRegex).map((part, index) => {
    const item = values[part] || part;
    if (!isValidElement(part)) return item;
    return cloneElement(item, { key: index });
  });

  const segments = replacedValueSegments.flatMap((segment: string | JSX.Element) => {
    if (!isValidElement(segment) && typeof segment === 'string') return segment.split(htmlRegex);
    return segment;
  });
  const parsedHtml: (string | JSX.Element)[] = [];
  let nextSegmentInserted = false;

  segments.forEach((segment, index) => {
    if (typeof segment === 'string' && segment.startsWith('<')) {
      // If the segment starts with '<', it's an HTML element
      const isClosingTag = segment.startsWith('</');
      // Remove '<' and '>', but remove 2 characters for closing tags
      if (!isClosingTag) {
        // If it's an opening tag, push it to the stack
        const tagName = segment.substring(1, segment.length - 1);
        parsedHtml.push(createElement(tagName, { key: index }, segments[index + 1]));
        nextSegmentInserted = true;
      } else nextSegmentInserted = false;
    } else if (!nextSegmentInserted) {
      // If the segment doesn't start with '<', it's text content
      // If nextSegmentInserted is true though, the text content was already inserted in the react element
      parsedHtml.push(segment);
    }
  });

  return parsedHtml;
}

type Values = { [key: string]: string | number | JSX.Element };
type ValuesString = { [key: string]: string };

export const getI18nUtils = (dictionary: Partial<Dictionaries>) => {
  function formatMessage(_param: { id: keyof Dictionaries | string }): string;
  function formatMessage(_param: { id: keyof Dictionaries | string }, _values?: ValuesString, _asString?: boolean): string;
  function formatMessage(
    _param: { id: keyof Dictionaries | string },
    _values: Values,
    _asString?: boolean
  ): (JSX.Element | string)[];
  function formatMessage(
    param: { id: keyof Dictionaries | string },
    values?: Values,
    asString?: boolean
  ): string | (JSX.Element | string)[] {
    const { id } = param || {};
    const translation: string = dictionary[id as string] || id;
    const parsedHtml = parseHtmlString(translation, values);
    if (asString) {
      return parsedHtml
        .map((part) => (typeof part === 'string' ? part : ''))
        .filter((part) => part !== '')
        .join('');
    }
    return parsedHtml;
  }

  return {
    formatMessage,
  };
};

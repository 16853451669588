'use client';

import { createContext, useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { useParams } from 'next/navigation';
import { User } from 'firebase/auth';
import { useSessionStorage } from 'usehooks-ts';
import { auth } from '../utils/firebase';
import { getCandidate, saveCandidate, getApplications } from '../api/candidate';

import { logSentryError } from '../utils/logSentry';
import axiosInstance from '../utils/axios';

interface UserContextType {
  user: User | null;
  candidate: Candidate | null;
  setCandidate: Dispatch<SetStateAction<Candidate>>;
  loading: boolean;
  signOut: (args?: any) => Promise<void>;
  jobApplications: any;
  jobApplicationsLoading: boolean;
  setCookieConsent: Dispatch<SetStateAction<{ accepted: any }>>;
  refetchCandidate: () => Promise<void | Candidate | null>;
  isLoggedIn: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

function UserContextProvider({ children }) {
  const { locale } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [loading, setLoading] = useState(true);
  const [jobApplications, setJobApplications] = useState(null);
  const [jobApplicationsLoading, setJobApplicationsLoading] = useState(false);
  const [cookieConsent, setCookieConsent] = useState({ accepted: null });
  const [sessionLikedJob, setSessionLikedJob] = useSessionStorage('likedJob', null);

  async function fetchCandidate(firebaseUser?: User) {
    if (!user && !firebaseUser) return setLoading(false);
    setLoading(true);
    let newCandidate: Candidate | null = null;
    try {
      const c = await getCandidate();
      setCandidate(c);
      newCandidate = c;
    } catch (error) {
      // user hasn't registered yet
    }

    setLoading(false);
    return newCandidate;
  }

  useEffect(() => {
    async function fetchJobApplications() {
      setJobApplicationsLoading(true);
      const userJobApplications = await getApplications();
      setJobApplications(userJobApplications);
      setJobApplicationsLoading(false);
    }
    if (candidate && candidate.id) fetchJobApplications();
  }, [candidate]);

  useEffect(() => {
    async function updateCandidate(fields) {
      await saveCandidate(fields);
      setCandidate((prev) => ({ ...prev, ...fields }));
    }
    if (candidate?.id && !candidate?.language) updateCandidate({ language: locale });
  }, [user, candidate, locale]);

  const handleIdTokenChange = async (firebaseUser: User) => {
    setUser(firebaseUser);
    if (firebaseUser) await fetchCandidate(firebaseUser);
    else {
      setCandidate(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(handleIdTokenChange);
    return unsubscribe;
  }, []);

  useEffect(() => {
    const likeJob = async (jobId) => {
      try {
        await axiosInstance.post(`/favorites/${jobId}`);
        setCandidate((prev) => {
          if (prev === null) return null;
          return {
            ...prev,
            favoriteJobIds: prev?.favoriteJobIds ? Array.from(new Set([...prev.favoriteJobIds, jobId])) : [jobId],
          };
        });
        setSessionLikedJob(null);
      } catch (error) {
        logSentryError(error);
      }
    };

    if (sessionLikedJob && user && candidate) likeJob(sessionLikedJob);
  }, [user, candidate]);

  useEffect(() => {
    if (cookieConsent.accepted !== null) {
      saveCandidate({
        cookieConsent: { accepted: cookieConsent.accepted, app: process.env.NEXT_PUBLIC_APP },
      });
    }
  }, [cookieConsent]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: !!(user && candidate?.id), // some properties may be set without the user having an account (f.e. from localStorage after job application). This property is convenient to determine if the user is actually logged in
        user,
        candidate,
        setCandidate,
        loading,
        signOut: () => auth.signOut(),
        jobApplicationsLoading,
        jobApplications,
        setCookieConsent,
        refetchCandidate: fetchCandidate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) throw new Error('useUser must be used within a UserProvider');

  return context;
}

export { useUser, UserContext };
export default UserContextProvider;

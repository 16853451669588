import { BiCookie } from 'react-icons/bi';
import CC from 'react-cookie-consent';
import { saveCandidate } from '../api/candidate';
import { useCookies } from '../contexts/CookiesContext';
import { useUser } from '../contexts/UserContext';
import { getI18nUtils } from '../i18n/i18n-utils';
import { usePathname } from 'next/navigation';
import { Dictionaries, getSynergiejobsDictionary, Locale } from '../i18n/i18n-config';
import { getLocaleFromPathname } from '../utils/getLocale';
import { useEffect, useState } from 'react';

function CookieConsent() {
  const { handleTrackingCookieConsent } = useCookies();
  const pathname = usePathname();
  const locale: Locale = pathname ? getLocaleFromPathname(pathname) : 'nl';
  const [dictionary, setDictionary] = useState<Partial<Dictionaries> | null>(null);
  useEffect(() => {
    const getDictionary = async () => {
      try {
        setDictionary(await getSynergiejobsDictionary(locale));
      } catch (error) {
        console.error('cannot get dictionary:', error);
      }
    };

    getDictionary();
  }, [locale]);
  const { user, candidate } = useUser();
  if (!dictionary) return null;
  const { formatMessage } = getI18nUtils(dictionary);
  const handleSubmit = (accepted: boolean) => {
    handleTrackingCookieConsent(accepted);
    if (user && candidate) saveCandidate({ cookieConsent: { accepted, app: process.env.NEXT_PUBLIC_APP } });
  };

  return (
    <CC
      disableStyles
      buttonClasses="mb-1"
      containerClasses="col-12 m-sm-3"
      contentClasses="d-flex flex-column justify-content-center"
      onAccept={() => handleSubmit(true)}
      onDecline={() => handleSubmit(false)}
      enableDeclineButton
      buttonText={formatMessage({ id: 'cookie-accept' })}
      declineButtonText={formatMessage({ id: 'cookie-decline' })}
      style={{
        maxWidth: '400px',
        position: 'fixed',
        bottom: '0px',
        background: '#fff',
        flexWrap: 'wrap',
        padding: '24px',
        borderRadius: '0.5rem',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        zIndex: '1001',
      }}
      buttonStyle={{
        color: 'white',
        backgroundColor: '#e2001a',
        borderRadius: '0.25rem',
        minWidth: '120px',
        padding: '8px 15px 8px 15px',
        fontSize: '14px',
        border: 'none',
      }}
      declineButtonStyle={{
        minWidth: '120px',
        backgroundColor: 'transparent',
        padding: '6px 15px 6px 15px',
        fontSize: '14px',
        color: '#2f3336',
        border: 'solid 1px #2f3336',
        borderRadius: '0.25rem',
        marginRight: '8px',
      }}
    >
      <div style={{ marginBottom: '16px' }}>
        <BiCookie size="32" color="#2f3336" className="me-2" />
      </div>
      <p style={{ fontSize: '14px', color: 'black', marginBottom: '24px' }}>{formatMessage({ id: 'cookie-consent' })}</p>
    </CC>
  );
}

export default CookieConsent;

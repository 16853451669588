import axiosInstance from '../utils/axios';
import { plausibleEvent } from '../utils/plausibleEvent';

const app = process.env.NEXT_PUBLIC_APP;

async function getCandidate() {
  const { data } = await axiosInstance.get<Candidate>(`/candidate`, { params: { app } });
  return data;
}

const profileUpdateForms = [
  'personal',
  'contact',
  'email',
  'resume',
  'job-preferences',
  'transportation',
  'acquaintance',
  'acquaintance-skip',
  'avatar',
] as const;
type ProfileUpdateForm = (typeof profileUpdateForms)[number];

async function saveCandidate(userData, type?: ProfileUpdateForm) {
  const { data } = await axiosInstance.patch(`/candidate`, userData);
  if (type) plausibleEvent('profile-update', { type });
  return data;
}

async function createCandidate(language = 'nl') {
  const { data } = await axiosInstance.post(`/candidate`, { language, app });
  return data;
}

async function getApplications(limit = 20) {
  const { data } = await axiosInstance.get(`/applications`, { params: { limit } });
  return data;
}

async function getJobmatches(maxHits = 9, fromHits = 0) {
  const { data } = await axiosInstance.get(`/job-matches`, { params: { maxHits, fromHits } });
  return data;
}

async function getCv() {
  const { data } = await axiosInstance.get(`/candidate/cv`);
  return data;
}

export { getCandidate, saveCandidate, createCandidate, getApplications, getJobmatches, getCv };

import { createContext, useState, useEffect, useContext } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import { startTracking, stopTracking } from '../utils/gtag';

interface CookiesContextType {
  enabledCookies: { tracking: boolean; functional: boolean; personal: boolean };
  handleTrackingCookieConsent: (accepted: boolean) => void;
}

const CookiesContext = createContext<CookiesContextType | undefined>(undefined);

function CookiesContextProvider({ children }) {
  const [enabledCookies, setEnabledCookies] = useState({
    tracking: getCookieConsentValue() === 'true',
    functional: true, // hardcoded atm, for future purpose
    personal: true, // hardcoded atm, for future purpose
  });

  useEffect(() => {
    if (enabledCookies.tracking) startTracking();
    else stopTracking();
  }, [enabledCookies.tracking]);

  function handleTrackingCookieConsent(accepted: boolean) {
    setEnabledCookies((prev) => ({ ...prev, tracking: accepted }));
  }

  return <CookiesContext.Provider value={{ enabledCookies, handleTrackingCookieConsent }}>{children}</CookiesContext.Provider>;
}

function useCookies() {
  const context = useContext(CookiesContext);
  if (context === undefined) throw new Error('useCookies must be used within a CookiesContextProvider');

  return context;
}

export { useCookies };
export default CookiesContextProvider;

'use client';

import SharedApp from 'shared/pages/_app';
import '@fontsource/open-sans/latin.css';
import '@fontsource/montserrat/latin.css';
import '../stylesheets/main.scss';
import CookiesContextProvider from 'shared/contexts/CookiesContext';
import PlausibleTracking from 'shared/components/tracking/PlausibleTracking';
import GoogleTagManagerTrackingCode from '../components/tracking/gTagTracking';

const WrappedApp = ({ children }: { children: React.ReactNode }) => (
  <>
    <CookiesContextProvider>
      <SharedApp>{children}</SharedApp>
      <GoogleTagManagerTrackingCode />
    </CookiesContextProvider>
    <PlausibleTracking />
  </>
);
export default WrappedApp;

'use client';

import React, { useEffect, useState } from 'react';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CookieConsent from 'shared/components/CookieConsent';
import UserContextProvider from '../contexts/UserContext';
import MySynergieNotificationsContextProvider from '../contexts/MySynergieNotificationsContext';

require('../utils/firebase');
require('dayjs/locale/nl-be');
require('dayjs/locale/fr');
require('dayjs/locale/pl');
require('dayjs/locale/ro');
require('dayjs/locale/en');

dayjs.extend(relativeTime);

dayjs.extend(customParseFormat, {
  // oplossing om jaar <= '86 uit RRNR correct te parsen, zie https://github.com/iamkun/dayjs/issues/1418
  parseTwoDigitYear: (yearString) => +yearString + (+yearString > +dayjs().format('YY') ? 1900 : 2000),
});

const MyApp = ({ children }) => {
  const { locale } = useParams();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [history, setHistory] = useState<string[]>([]);
  const initDayJs = async (userLocale) => {
    switch (userLocale) {
      case 'fr':
        dayjs.locale('fr');
        break;
      case 'pl':
        dayjs.locale('pl');
        break;
      case 'ro':
        dayjs.locale('ro');
        break;
      case 'en':
        dayjs.locale('en');
        break;
      case 'nl':
        dayjs.locale('nl-be');
        break;
      default:
        dayjs.locale('nl-be');
        break;
    }
  };

  initDayJs(locale);

  useEffect(() => {
    const utm_source = searchParams.get('utm_source') || null;
    const utm_medium = searchParams.get('utm_medium') || null;
    const utm_campaign = searchParams.get('utm_campaign') || null;
    // add the source to sessionStorage so we can retrieve when submitting an ApplicationForm
    if (
      typeof window !== 'undefined' &&
      typeof window.sessionStorage !== 'undefined' && // check browser support
      window.sessionStorage // check browser support
    ) {
      if (utm_source && typeof utm_source === 'string') window.sessionStorage.setItem('utm_source', utm_source);
      if (utm_medium && typeof utm_medium === 'string') window.sessionStorage.setItem('utm_medium', utm_medium);
      if (utm_campaign && typeof utm_campaign === 'string') window.sessionStorage.setItem('utm_campaign', utm_campaign);
      window.sessionStorage.setItem('referrer', document.referrer || 'null');
    }
  }, [searchParams]);

  useEffect(() => {
    try {
      // Register service worker if available
      if ('serviceWorker' in navigator) navigator.serviceWorker.register('/service-worker.js');
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (history && history[history.length - 1] !== pathname) setHistory((prev) => [...prev, pathname]);
  }, [pathname, history]);

  return (
    <UserContextProvider>
      <MySynergieNotificationsContextProvider>
        {children}
        <CookieConsent />
      </MySynergieNotificationsContextProvider>
    </UserContextProvider>
  );
};

export default MyApp;

import { getCookieConsentValue, Cookies } from 'react-cookie-consent';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
const dev = process.env.NODE_ENV === 'development';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (window && typeof window !== 'undefined' && getCookieConsentValue() === 'true') {
    // Google Analytics
    if (window.gtag) {
      window.gtag('config', GA_TRACKING_ID, {
        page_location: url,
      });
      window.gtag('config', 'UA-28410282-1', {
        page_location: url,
      });
      window.gtag('config', 'G-81644KKL5E', {
        page_location: url,
      });
    }
    // Facebook
    if (window.fbq) window.fbq('track', 'PageView');
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (window && typeof window !== 'undefined' && getCookieConsentValue() === 'true') {
    // Google Analytics
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      });
    }
    // Facebook
    if (window.fbq && typeof action === 'string') {
      switch (action) {
        case 'apply':
          window.fbq('track', 'SubmitApplication');
          break;
        default:
        // do nothing
      }
    }
  }
};

export const startTracking = () => {
  const gtagScript = document.createElement('script');
  gtagScript.type = 'text/javascript';
  gtagScript.async = true;
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  document.head.appendChild(gtagScript);
};

export const stopTracking = () => {
  const domain = dev ? window.location.hostname.replace('www.', '') : `.${window.location.hostname.replace('www.', '')}`;

  // remove google analytics cookies
  Cookies.remove('_ga', { domain, path: '/' });
  Cookies.remove('_gat', { domain, path: '/' });
  Cookies.remove('_gid', { domain, path: '/' });
  // remove facebook pixel cookie
  Cookies.remove('_fbp', { domain, path: '/' });
  // remove tiktok cookies
  Cookies.remove('_ttwid', { domain, path: '/' });
  Cookies.remove('_ttp', { domain, path: '/' });
  // remove linkedin cookies
  Cookies.remove('lidc', { domain, path: '/' });
  Cookies.remove('li_sugr', { domain, path: '/' });
  Cookies.remove('li_gc', { domain, path: '/' });
  Cookies.remove('ln_or', { domain, path: '/' });
};

import { Dispatch, useState, useEffect, useCallback, SetStateAction } from 'react';
import { logSentryError } from '../utils/logSentry';
import axiosInstance from '../utils/axios';

const useData = <T = any>(
  endpoint: string,
  initialData?: T
): {
  data: T | undefined;
  isLoading: boolean;
  errorMessage: string | null;
  refetchData: () => Promise<void>;
  setData: Dispatch<SetStateAction<T | undefined>>;
} => {
  const [data, setData] = useState<null | any>(initialData);
  const [isLoading, setIsLoading] = useState(!!endpoint);
  const [errorMessage, setErrorMessage] = useState(null);
  const fetchData = useCallback(async () => {
    setErrorMessage(null);
    setIsLoading(true);

    try {
      const { data: d } = await axiosInstance.get(endpoint);
      setData(d);
      setIsLoading(false);
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message || error.message);
      setData(null);
      setIsLoading(false);
      logSentryError(error);
    }
  }, [endpoint]);

  useEffect(() => {
    if (!endpoint) setData(null);
    else fetchData();
  }, [endpoint, fetchData]);

  return { data, isLoading, errorMessage, refetchData: fetchData, setData };
};

export default useData;

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBo-WMkFAVR2Vaa_igZf_HmcxYNKD8URwQ',
  authDomain: 'synergiejobs-be.firebaseapp.com',
  databaseURL: 'https://synergiejobs-be.firebaseio.com',
  projectId: 'synergiejobs-be',
  storageBucket: 'synergiejobs-be.appspot.com',
  messagingSenderId: '597996836457',
  appId: '1:597996836457:web:f1e2c5d00414069c',
  measurementId: 'G-0FWVXEYNPG',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

export default app;
